import React from 'react';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import BestMonths from '../../Recommendations/BestMonths';
import BestAges from '../../Recommendations/BestAges';
import Recommendations from '../../Recommendations/Recommendations';
import Legend from '../../Recommendations/Legend';
import getImageUrl from '../../Images/ImageUtils';
import ScheduleComponentV2 from '../../ScheduleComponentV2/ScheduleComponentV2';
import DOMPurify from 'dompurify';

function LocationInfo({ location, openImage }) {
  return (
    <div className="experience-info">
      {/* eslint-disable-next-line */}
      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(location.description) }} />
      {location.additional_images.length > 0 && (
        // eslint-disable-next-line
        <img
          className="additional-image"
          src={getImageUrl(location.additional_images[0])}
          style={{
            objectPosition: `${location.additional_images[0].interest_x}% ${location.additional_images[0].interest_y}%`,
          }}
          alt={location.name}
          onClick={() => openImage(1)}
        />
      )}
      {location.additional_images.length > 1 && (
        <Grid container spacing={1}>
          <Grid item xs={4}>
            {/* eslint-disable-next-line */}
            <img
              className="additional-image small"
              src={getImageUrl(location.additional_images[1])}
              style={{
                objectPosition: `${location.additional_images[1].interest_x}% ${location.additional_images[1].interest_y}%`,
              }}
              alt={location.name}
              onClick={() => openImage(2)}
            />
          </Grid>
          {location.additional_images.length > 2 && (
            <Grid item xs={4}>
              {/* eslint-disable-next-line */}
              <img
                className="additional-image small"
                src={getImageUrl(location.additional_images[2])}
                style={{
                  objectPosition: `${location.additional_images[2].interest_x}% ${location.additional_images[2].interest_y}%`,
                }}
                alt={location.name}
                onClick={() => openImage(3)}
              />
            </Grid>
          )}
          {location.additional_images.length > 3 && (
            <Grid item xs={4}>
              {/* eslint-disable-next-line */}
              <img
                className="additional-image small"
                src={getImageUrl(location.additional_images[3])}
                style={{
                  objectPosition: `${location.additional_images[3].interest_x}% ${location.additional_images[3].interest_y}%`,
                }}
                alt={location.name}
                onClick={() => openImage(4)}
              />
            </Grid>
          )}
        </Grid>
      )}
      {(location.recommended_months || location.recommended_children_ages) && (
        <h2 style={{ textAlign: 'center' }}>When to Go?</h2>
      )}
      {location.recommended_months && (
        <BestMonths months={location.recommended_months} />
      )}
      {location.recommended_children_ages && (
        <Box sx={{ mt: 1.5 }}>
          <BestAges ages={location.recommended_children_ages} />
        </Box>
      )}
      {(location.recommended_months || location.recommended_children_ages) && (
        <Legend />
      )}

      {location && location.type === 'country' && (
        <ScheduleComponentV2 item={location} />
      )}

      {location.recommendations && (
        <Recommendations recommendations={location.recommendations} />
      )}
    </div>
  );
}

LocationInfo.propTypes = {
  // eslint-disable-next-line
  location: PropTypes.object.isRequired,
  openImage: PropTypes.func.isRequired,
};

export default LocationInfo;
