import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import {
  IonPage, IonHeader, IonToolbar, IonButton, IonButtons, IonTitle, IonContent,
} from '@ionic/react';
import { IconArrowLeft, IconChevronDown } from '@tabler/icons-react';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { API_URL } from '../../Constants/URLS';
import DOMPurify from 'dompurify';

function SupportView() {
  const [questions, setQuestions] = useState([]);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [expanded, setExpanded] = useState('');
  const history = useHistory();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    api.get(`${API_URL}/administration/support-questions/`)
      .then((response) => {
        setQuestions(response.data);
      });
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              className="ion-back-button ion-cancel"
              onClick={() => history.back()}
            >
              <IconArrowLeft />
            </IonButton>
          </IonButtons>
          <IonTitle>
            Support
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="support-content">
          {questions.map((question) => (
            <Accordion
              expanded={expanded === question.id}
              onChange={handleChange(question.id)}
            >
              <AccordionSummary
                aria-controls={`${question.id}-content`}
                id={`${question.id}-header`}
                expandIcon={<IconChevronDown />}
              >
                {question.question}
              </AccordionSummary>
              <AccordionDetails>
                {/* eslint-disable-next-line */}
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.answer) }} />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </IonContent>
    </IonPage>
  );
}

export default SupportView;
