import React from 'react';
import { TbClock2, TbMapPin } from 'react-icons/tb';
import { Chip, Grid, Box } from '@mui/material';
import PropTypes from 'prop-types';
import BestMonths from '../../Recommendations/BestMonths';
import BestAges from '../../Recommendations/BestAges';
import Recommendations from '../../Recommendations/Recommendations';
import Legend from '../../Recommendations/Legend';
import getImageUrl from '../../Images/ImageUtils';
import DOMPurify from 'dompurify';

function ExperienceInfo({ experience, openImage }) {
  return (
    <div className="experience-info">
      <span className="info">
        <TbMapPin />
        Location:
        {' '}
        <b>{experience.location_destination.full_name}</b>
      </span>
      <span className="info">
        <TbClock2 />
        Duration:
        {' '}
        <b>
          {experience.duration_hours}
          {' '}
          hours
        </b>
      </span>
      {/* eslint-disable-next-line */}
      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(experience.description) }} />
      {experience.additional_images.length > 0 && (
        // eslint-disable-next-line
        <img
          className="additional-image"
          src={getImageUrl(experience.additional_images[0])}
          style={{
            objectPosition: `${experience.additional_images[0].interest_x}% ${experience.additional_images[0].interest_y}%`,
          }}
          alt={experience.experience.name}
          onClick={() => openImage(1)}
        />
      )}
      {experience.additional_images.length > 1 && (
        <Grid container spacing={1}>
          <Grid item xs={4}>
            {/* eslint-disable-next-line */}
            <img
              className="additional-image small"
              src={getImageUrl(experience.additional_images[1])}
              style={{
                objectPosition: `${experience.additional_images[1].interest_x}% ${experience.additional_images[1].interest_y}%`,
              }}
              alt={experience.experience.name}
              onClick={() => openImage(2)}
            />
          </Grid>
          {experience.additional_images.length > 2 && (
            <Grid item xs={4}>
              {/* eslint-disable-next-line */}
              <img
                className="additional-image small"
                src={getImageUrl(experience.additional_images[2])}
                style={{
                  objectPosition: `${experience.additional_images[2].interest_x}% ${experience.additional_images[2].interest_y}%`,
                }}
                alt={experience.experience.name}
                onClick={() => openImage(3)}
              />
            </Grid>
          )}
          {experience.additional_images.length > 3 && (
            <Grid item xs={4}>
              {/* eslint-disable-next-line */}
              <img
                className="additional-image small"
                src={getImageUrl(experience.additional_images[3])}
                style={{
                  objectPosition: `${experience.additional_images[3].interest_x}% ${experience.additional_images[3].interest_y}%`,
                }}
                alt={experience.experience.name}
                onClick={() => openImage(4)}
              />
            </Grid>
          )}
        </Grid>
      )}
      <Box sx={{ mt: 2 }} />
      {experience.experience.interest_categories.map((category) => (
        <Chip label={category.name} />
      ))}
      <h2>About the location</h2>
      {/* eslint-disable-next-line */}
      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(experience.location_destination.description) }} />
      {(experience.recommended_months || experience.recommended_children_ages) && (
        <h2 style={{ textAlign: 'center' }}>When to Go?</h2>
      )}
      {experience.recommended_months && (
        <BestMonths months={experience.recommended_months} />
      )}
      {experience.recommended_children_ages && (
        <Box sx={{ mt: 1.5 }}>
          <BestAges ages={experience.recommended_children_ages} />
        </Box>
      )}
      {(experience.recommended_months || experience.recommended_children_ages) && (
        <Legend />
      )}
      <Recommendations recommendations={experience.recommendations} />
    </div>
  );
}

ExperienceInfo.propTypes = {
  // eslint-disable-next-line
  experience: PropTypes.object.isRequired,
  openImage: PropTypes.func.isRequired,
};

export default ExperienceInfo;
